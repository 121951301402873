/*
 * @Author: 冯杰
 * @Date: 2021-07-13 17:45:58
 * @LastEditTime: 2021-10-10 20:05:13
 * @FileName: 文件名
 */
import Vue from '@/found/main';
import App from './App.vue';
import router from './router';
import store from './store';
import precise from './utils/precise';

Vue.use(precise);
// Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('TokenKey');
  // if (to.meta.title && typeof to.meta.title === 'string') {
  //   document.title = to.meta.title;
  // }
  if (token) {
    if (!store.state.menus.menusName.length) {
      store.dispatch('menus/getMenusNavList').then((res) => {
        if (res.length > 0 && to.path.indexOf('/login') === 0) {
          next({ path: res[0].path !== 'census' ? res[0].path : '/' });
        } else if (res.length > 0 && to.redirectedFrom) {
          const path = res[0].path !== 'census' && to.redirectedFrom === '/';
          next(path ? res[0].path : to.redirectedFrom);
        } else {
          next();
        }
      }).catch((err) => {
        next();
        console.log(err);
      });
    } else {
      next();
    }
  } else if (!token && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
});

window.App = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
